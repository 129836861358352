<template>
  <div class="page">
    <div class="main user">
      <div class="banner" style="background-image: url(/bg.png)">
        <img
          class="logo"
          :src="userInfo.headimgurl || '/user_logo.png'"
          @click="navTo('/login/' + shop_id)"
          alt=""
        />
        <div class="name" @click="navTo('/login/' + shop_id)">
          {{ userInfo.nickname || "点击登录" }}
        </div>
      </div>

      <div class="list2" style="margin: 60px auto">
        <div
          class="item"
          v-for="(item, index) in navList"
          :key="index"
          @click="navTo(item.link)"
        >
          <div class="title">
            <img :src="item.icon" alt="" />
          </div>
          <div class="after">{{ item.title }}</div>
          <img class="arr" src="/right.png" alt="" />
        </div>
      </div>

      <button class="logout" @click="logout">退出登录</button>

      <div class="copyright">&copy; 微拍预展平台</div>
    </div>
  </div>
</template>

<style lang='scss' scord>
  .logout{
    width: 200px;
    height: 44px;
    display: block;
    margin: 20px auto;
    border: none;
    background: #efefef;
  }
</style>

<script>
export default {
  data() {
    return {
      shop_id: null,
      userInfo: {},
    };
  },

  async created() {
    this.shop_id = this.$route.params.shop_id;

    this.navList = [
      {
        title: "我的收藏",
        icon: "/fav.png",
        link: "/fav/" + this.shop_id,
      },
      {
        title: "联系客服",
        icon: "/kefu2.png",
        link: "/kefu/" + this.shop_id,
      },
      {
        title: "商家介绍",
        icon: "/shop.png",
        link: "/shopInfo/" + this.shop_id,
      },
      {
        title: "送评服务",
        icon: "/service2.png",
        link: "/service/" + this.shop_id,
      },
      // {
      //   title: "使用说明",
      //   icon: "/info.png",
      //   link: "/article/" + this.shop_id,
      // },
      // {
      //   title: "平台规则",
      //   icon: "/readme.png",
      //   link: "/article/" + this.shop_id,
      // },
    ];

    const token = this.$store.state.token;
    if (!token) return this.userInfo = {};
    const r = await this.$axios.get("/user/getUserInfo");
    console.log(r);
    if (r.status == 1) {
      this.userInfo = r.userInfo;
    }
  },

  methods: {
    logout () {
      this.userInfo = {};
      this.$store.commit('saveToken', null)
      localStorage.clear()
    },
    navTo(link) {
      this.$router.push(link);
    },
  },
};
</script>